<template>
  <div class="search-card-wrapper">
    <div class="search-card" v-for="carDetail in listings" :key="carDetail.ListingId">
      <TheCarCard
        :carDetail="carDetail"
        :price="carDetail.Price"
        wishlist
        class="mx-auto"
      />
      <!-- <TheCarCard
          :carDetail="carDetail"
          wishlist
          buttonType="reserve"
        ></TheCarCard> -->
    </div>
    <!--
    <b-col class="text-center py-5">
      <b-button
        v-if="listings.length > 6"
        @click="scrollToTop()"
        variant="primary"
        pill
      >
        {{ $t("search.label.backToTop") }}
      </b-button>
    </b-col>
-->
  </div>
</template>

<script>
export default {
  components: {
    TheCarCard: () => import("@/components/global/TheCarCard.vue"),
  },
  props: ["listings"],
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getPrice(prices) {
      return JSON.parse(prices)[0].Price;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  padding: 0;
}
.card-body {
  padding: 0;
}
.search-card-wrapper {
  display: flex;
  align-items: flex-start;
  column-gap: 16px;
  row-gap: 16px;
  flex-wrap: wrap;
}
.search-card {
  width: calc((100% / 3) - 11px);
  transition: 0.3s;
  @media (max-width: 991px) {
    width: calc((100% / 2) - 8px);
  }
  @media (max-width: 575px) {
    width: 100%;
  }
  &:hover {
    box-shadow: 0 5px 14px -3px #b9b9b9;
  }
}
</style>
