<template>
  <v-menu
    :attach="isMobile ? `#${label}_Menu_Mobile` : `#${label}_Menu`"
    max-width="400px"
    max-height="400px"
    content-class="menuClass"
    v-model="isOpen"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <TopFilterBtn
        :label="label"
        :attrs="attrs"
        :on="on"
        :class="{ activatorDisabled: disabled }"
        :id="isMobile ? `${label}_Menu_Mobile` : `${label}_Menu`"
      />
    </template>
    <v-card>
      <v-card-title class="pb-0" @click.stop v-if="showSearch">
        <v-text-field
          v-model="search"
          :label="$t('search.label.SearchLabel')"
          clearable
          dense
          hide-details="auto"
          prepend-inner-icon="mdi-magnify"
          class="pb-2"
        />
      </v-card-title>
      <v-list class="menuListClass" :dense="multiple" :width="width">
        <SearchTopSelectorList
          v-if="!grouped"
          v-bind:selectedValue.sync="selected"
          :itemValue="itemValue"
          :itemText="itemText"
          :itemCount="itemCount"
          :multiple="multiple"
          :searched-list="searchedList"
          :selected="selected"
          :setValue="setValue"
          @applySingle="(val) => applySingle(val)"
        />
        <template v-else v-for="(group, index) in searchedListGroup">
          <SearchTopSelectorList
            v-if="group.length > 0"
            :key="listGroupTitle[index]"
            v-bind:selectedValue.sync="selected"
            :itemValue="itemValue"
            :itemText="itemText"
            :itemCount="itemCount"
            :multiple="multiple"
            :searched-list="group"
            :selected="selected"
            :setValue="setValue"
            :title="listGroupTitle[index]"
          />
        </template>
      </v-list>
      <v-card-actions @click.stop v-if="multiple">
        <v-row>
          <v-col cols="5">
            <v-checkbox
              hide-details
              :label="checkSelect"
              :indeterminate="checkIndeterminate"
              class="selectAllClass"
              color="primary"
              :value="selected.length === optionsList.length"
              @change="selectAll"
              v-if="showSelectAll"
            />
          </v-col>
          <v-col cols="7" class="text-right">
            <v-btn small text @click="clear" class="mr-1"> {{ $t('search.label.Clear') }} </v-btn>
            <v-btn small color="primary" @click="apply"> {{ $t('search.label.Apply') }} </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import SearchTopSelectorList from "./SearchTopSelectorList";
import chevronDown from "@/assets/img/icons/chevron-down.svg";
import TopFilterBtn from "./TopFilterBtn";

export default {
  name: "SearchTopSelector",
  props: {
    label: String,
    optionsList: [Array, Object],
    selectedValue: [Array, String, Number],
    showSearch: Boolean,
    showSelectAll: Boolean,
    itemValue: String,
    itemText: String,
    itemCount: String,
    multiple: Boolean,
    disabled: Boolean,
    grouped: Boolean,
    width: String,
    isMobile: Boolean,
  },
  components: {
    SearchTopSelectorList,
    TopFilterBtn,
  },
  watch: {
    isOpen(val) {
      if (val && this.selectedValue) this.selected = this.selectedValue;
      if (!val) this.closeMenu();
    },
  },
  data() {
    return {
      isOpen: false,
      selected: [],
      search: undefined,
      chevronDown,
    };
  },
  methods: {
    selectAll(val) {
      if (val) {
        if (this.grouped) {
          this.selected = Object.keys(this.optionsList)
            .map((key) => this.optionsList[key])
            .reduce(
              (prev, curr) => [...prev, ...curr.map((item) => item[this.itemValue])],
              []
            );
        } else {
          this.selected = this.optionsList.map((item) => item[this.itemValue]);
        }
      } else {
        this.selected = [];
      }
    },
    clear() {
      this.$emit("apply", { selected: [] });
      this.closeMenu();
    },
    apply() {
      this.$emit("apply", { selected: this.selected });
      this.closeMenu();
    },
    applySingle(item) {
      console.log("item", item);
      if (!this.multiple) {
        this.$emit("apply", { selected: item });
        this.closeMenu();
      }
    },
    closeMenu() {
      this.isOpen = false;
      this.selected = [];
      this.search = undefined;
    },
    setValue(selected) {
      this.selected = selected;
    },
  },
  computed: {
    searchedList() {
      if (this.search) {
        return this.optionsList.filter(
          (item) => item.Value.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        );
      } else {
        return this.optionsList;
      }
    },
    searchedListGroup() {
      if (this.search) {
        return Object.keys(this.optionsList).map((key) =>
          this.optionsList[key].filter(
            (item) => item.Value.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
          )
        );
      } else {
        return Object.keys(this.optionsList).map((key) => this.optionsList[key]);
      }
    },
    listGroupTitle() {
      return Object.keys(this.optionsList);
    },
    checkSelect() {
      if (this.selected.length === this.optionsList.length) {
        return this.$t('search.label.DeSelectAll');
      } else {
        return this.$t('search.label.SelectAll');
      }
    },
    checkIndeterminate() {
      return this.selected.length > 0 && this.selected.length !== this.optionsList.length;
    },
  },
};
</script>
<style lang="scss">
.menuClass {
  margin-top: 50px;
  cursor: default;
  overflow: hidden;
  @media (max-width: 991px) {
    left: 16px !important;
  }
}

.menuListClass {
  overflow-y: scroll;
  height: 300px;
  width: 330px;
}

.expanded {
  transform: rotate(180deg);
  display: block;
}

.selectAllClass {
  padding-top: 3px;
  margin-top: 0;
}
.selectAllClass label {
  padding: 0;
  margin: 0;
}
</style>
