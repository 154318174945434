<template>
  <div class="py-2 max-h-w mx-auto">
    <b-row>
      <b-col class="md-none mobileTopFilterButton" lg="12" @click="showSortAndFilterMobile = !showSortAndFilterMobile">
        <div class="filter-row" >
          <img src="@/assets/img/icons/filter.svg" alt="" />
          Filter & Sort
        </div>
        <b-icon icon="chevron-down" class="text-white" v-show="!showSortAndFilterMobile"/>
        <b-icon icon="chevron-up" class="text-white"  v-show="showSortAndFilterMobile"/>
      </b-col>
      <b-col lg="8" class="reservationFilterBox" :class="{'hideFilter': !showSortAndFilterMobile}">
        <SearchTopSelector
          :label="$t('search.label.sort')"
          item-value="value"
          item-text="label"
          :options-list="setSortObject"
          :selectedValue="getUserFilterByKey('Sort')"
          @apply="(value) => callBack(value, 'Sort')"
          width="280px"
        />
        <SearchTopSelector
          :label="$t('search.label.make')"
          :selectedValue="getUserFilterByKey('Make')"
          item-value="TagValueId"
          item-text="Value"
          item-count="Count"
          :options-list="getFilterList('Make')"
          showSearch
          showSelectAll
          multiple
          @apply="(value) => callBack(value, 'Make')"
          width="330px"
        />
        <SearchTopSelector
          :label="$t('search.label.model')"
          :selectedValue="getUserFilterByKey('Model')"
          item-value="TagValueId"
          item-text="Value"
          item-count="Count"
          :options-list="getFilterList('Model', getUserFilterByKey('Make'), 'Make')"
          showSearch
          showSelectAll
          multiple
          grouped
          @apply="(value) => callBack(value, 'Model')"
          :disabled="getUserFilterByKey('Make').length < 1"
          width="330px"
        />
        <YearSelector
          @apply="(value) => callBack(value, 'Year')"
          :selected-value="getUserFilterByKey('Year')"
          :min="getYearMin"
          :max="getYearMax"
        />
            <!--
            <div>
              <TopFilterBtn label="All Filters">
                <template v-slot:default>
                  <img :src="Filter" alt="filter" />
                </template>
              </TopFilterBtn>
            </div>
-->
      </b-col>
      <div class="text-white text-lg-right delivery-part">
        <h6 class="m-0">
          <b-icon icon="geo-alt" scale="1.5" class="map-icon" variant="warning" />
          <span>{{$t("search.label.DeliverTo")}}:</span>
          <b-btn
            variant="link"
            class="text-white pb-0 pl-0 f-c-c"
            @click.stop.prevent="$bvModal.show('selectDeliveryCountryCurrencyFull')"
          >
            <h6>
              <span class="ml-2" v-if="destinationCountry">{{
                destinationCountry.Name
              }}</span>
              -
              <span v-if="currentCurrency">{{ currentCurrency }}</span>
              <span v-else>{{$t("contactInfo.label.selectCountry")}}</span>
              <b-icon font-scale="1" class="pl-1" icon="chevron-down" />
            </h6>
          </b-btn>
        </h6>
      </div>
    </b-row>
  </div>
</template>

<script>
import SearchTopSelector from "./SearchTopSelector";
import YearSelector from "./YearSelector";
import { mapGetters, mapActions } from "vuex";
import _difference from "lodash/difference";
import _filter from "lodash/filter";
import _cloneDeep from "lodash/cloneDeep";
import {FilterTagMapping} from "../../utils/filters";

export default {
  data() {
    return {
      filterMobile: false,
      showSortAndFilterMobile: false
    };
  },
  watch: {
    selectedMakes: function (newVal, oldVal) {
      const diff = _difference(oldVal, newVal);
      if (!diff || diff.length < 1) return;
      const filter = _cloneDeep(this.getUserFilters);
      const allFilter = _cloneDeep(this.filters);
      diff.forEach((id) => {
        try {
          let modelsMake = _filter(allFilter, { TagId: FilterTagMapping.Model });
          if (modelsMake && modelsMake[0]) {
            modelsMake = _filter(modelsMake[0].Values, {
              ParentTagValueId: id,
            }).map((model) => model.TagValueId);
            filter["Model"] = _filter(
              filter["Model"],
              (item) => !modelsMake.includes(item)
            );
          }
        } catch (e) {
          console.log("error remove Binding", e);
        }
      });
      this.setUserFilter({ type: "Model", selected: filter["Model"] });
    },
  },
  components: {
    SearchTopSelector,
    // TopFilterBtn,
    YearSelector,
  },
  computed: {
    ...mapGetters([
      "getUserFiltersByType",
      "getYearMax",
      "getYearMin",
      "filters",
      "getUserFilters",
      "getUserFilterList",
      "getUserFilterValToLabel",
      "getUserFilterLabelToVal",
    ]),
    selectedMakes() {
      return this.$store.state.search.userFilter["Make"];
    },
    destinationCountry() {
      if (this.$store.state.search.destinationCountry?.Code) {
        return this.$store.state.search.destinationCountry;
      } else {
        return false;
      }
    },
    currentCurrency() {
      if (this.$store.getters.currency) {
        return ["XAF","XOF"].includes(this.$store.getters.currency) ? "FCFA" : this.$store.getters.currency ;
      } else {
        return false;
      }
    },
    setSortObject() {
      return [
        {
          label: this.$t("search.label.newOld"),
          value: 6
        },
        {
          label: this.$t("search.label.oldNew"),
          value: 5
        },
        {
          label: this.$t("search.label.priceLowHigh"),
          value: 1
        },
        {
          label: this.$t("search.label.priceHighLow"),
          value: 2
        },
        {
          label: this.$t("search.label.mileLowHigh"),
          value: 3
        },
        {
          label: this.$t("search.label.mileHighLow"),
          value: 4
        },
      ];
    },
  },
  methods: {
    ...mapActions(["setUserFilter"]),
    getFilterList(TagName, bindWithParent, bindWithParentName) {
      return this.getUserFilterList(TagName, bindWithParent, bindWithParentName);
    },
    callBack(selected, type) {
      this.$store.dispatch("setUserFilter", {
        type,
        selected: selected.selected,
      });
    },
    getUserFilterByKey(type) {
      return this.getUserFiltersByType(type);
    },
  },
};
</script>
<style scoped lang="scss">
.mobileTopFilterButton{
  display: none;
  @media (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.filter-row {
  font-size: 14px;
  color: white;
  display: inline-flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
  img {
    width: 22px;
  }
}
.delivery-part {
  display: flex;
  align-items: center;
  margin-left: auto;
  @media (max-width: 991px) {
    border-top: 1px solid #ffffff3d;
    width: 100%;
    padding: 10px 10px;
  }
  h6 {
    @media (max-width: 991px) {
      font-size: 14px;
    }
  }
  .map-icon {
    margin-right: 8px;
  }
}
.modal-filter {
  background: white;
  position: fixed;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: 1000;
  right: 0;
  overflow-y: auto;
  padding-top: 10px;
  transform: translateX(-100%);
  transition: 0.3s;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-bottom: 90px;
  &.open {
    transform: translateX(0);
  }
  &-header {
    font-size: 16px;
    font-weight: 600;
    color: #1c1c1c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding: 0 16px;
    button {
      width: 24px;
      height: 24px;
      background: #cdcdcd;
      border-radius: 100%;
      color: #585858;
      i {
        position: relative;
        top: -1px;
      }
    }
  }
  &-body {
    margin-top: 10px;
    margin-bottom: auto;
    .activator {
      padding: 0;
      height: 50px;
      border-bottom: 1px solid #cdcdcd;
      border-radius: 0;
    }
  }
}
.modal-filter-footer {
  background: #ffffff;
  box-shadow: 0px -5px 6px rgb(0 0 0 / 6%);
  height: 90px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  position: absolute;
  bottom: 0;
  width: 100%;
  right: 0;
  z-index: 100000;
  button {
    border-radius: 45px;
    height: 42px;
    width: 100%;
  }
}
</style>
<style lang="scss">
.reservationFilterBox{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;

  > *:not(.v-menu) {
    flex: none;
    width: 100px !important;
  }
  @media (max-width: 767px) {
    &.hideFilter{
      display: none;
    }
  }
}
</style>