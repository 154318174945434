<template>
  <div
    :id="`${label}_Menu`"
    v-bind="attrs"
    v-on="on"
    class="activator"
    :class="{ activatorDisabled: disabled }"
  >
    {{ label }}
    <img
      v-if="!$slots.default"
      :src="chevronDown"
      :class="{
        expanded: attrs && JSON.parse(attrs['aria-expanded'].toLowerCase())
      }"
      alt="chevronDown"
    />
    <slot v-else />
  </div>
</template>

<script>
import chevronDown from "@/assets/img/icons/chevron-down.svg";

export default {
  name: "topFilterBtn",
  props: ["label", "disabled", "on", "attrs"],
  data() {
    return {
      chevronDown
    };
  }
};
</script>

<style scoped>
.activator {
  padding: 0.5rem 1rem;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 35px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  cursor: pointer;
}
.activatorDisabled {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: default;
}
.activatorDisabled img {
  opacity: 0.25;
}

</style>
