<template>
  <div>
    <div class="border-bottom SearchBarHeader">
      <div class="bg-primary">
        <b-container class="mx-auto pb-lg-2 pt-lg-2 py-0">
          <TheSearchBarHeader />
        </b-container>
      </div>
      <div class="bg-light shadow-sm" v-if="$store.getters.filters">
        <b-container class="py-0">
          <TheSearchFilterHorizontal :loading="loading" :count="this.CountAll" />
        </b-container>
      </div>
    </div>

    <b-container class="mx-auto pt-0 mt-3">
      <b-row align-h="center" v-if="loading">
        <b-col class="text-center mt-2 px-1">
          <div class="wrapper">
            <div
              class="item"
              v-for="(item, index) in new Array(12)"
              :key="'loader' + index + item"
            >
              <v-skeleton-loader
                class="mx-auto my-2 px-2"
                max-width="100%"
                type="image, article"
              />
            </div>
          </div>
        </b-col>
      </b-row>
      <div v-else-if="listings">
        <div v-if="listings.length > 0">
          <TheSearchResultsCarCards :listings="listings" />
          <!-- <div class="text-center" v-if="PageSize * PageNo < CountAll || loadingMore"> -->
          <!-- <b-button @click="getNext" variant="primary" :disabled="loadingMore">
                  Load More
                </b-button> -->
          <!-- </div> -->
          <div class="search-result">
            <div class="current-count" v-html="$t('search.label.listPagination',[`<b>${listings.length}</b>`, `<b>${CountAll}</b>`])" />
            <div class="text-center" v-if="PageSize * PageNo < CountAll || loadingMore">
              <b-button
                @click="getNext"
                variant="outline-primary"
                :disabled="loadingMore"
              >
                <v-progress-circular indeterminate color="primary" v-if="loadingMore" />
                {{ $t('search.label.SeeMoreResults') }}
              </b-button>
            </div>
          </div>
        </div>
        <div align-h="center" class="p-5 my-5" v-else>
          <h3>{{ $t("search.label.noResults") }}</h3>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import TheSearchResultsCarCards from "@/components/Search/TheSearchResultsCarCards.vue";
import TheSearchBarHeader from "@/components/Search/TheSearchBarHeader.vue";
import { mapGetters } from "vuex";
import _cloneDeep from "lodash/cloneDeep";
import _merge from "lodash/merge";
import _find from "lodash/find";

import { FilterTagMapping } from "../../utils/filters"
export const blankUserFilter = {
  sort: undefined,
  Make: [],
  Model: [],
  Year: [],
}
export const searchLastPosition = 'searchLastPosition'
export default {
  name: "SearchResults",
  components: {
    TheSearchResultsCarCards,
    TheSearchBarHeader,
    TheSearchFilterHorizontal: () =>
      import("@/components/Search/TheSearchFilterHorizontal.vue"),
  },
  data() {
    return {
      sortBy: null,
      search: this.$store.getters.searchResult,
      errorMessage: null,
      newFilters: null,
      selected: {},
      error: null,
      sortOrder: 0,
      content: "",
      loading: false,
      loadingMore: false,
    };
  },
  computed: {
    filters() {
      return this.$store.getters.filters;
    },
    ...mapGetters([
      "activeBuyerCountries",
      "getUserFilters",
      "getUserFilterValToLabel",
      "getUserFilterLabelToVal",
      "getYearMax",
      "getYearMin",
      "filters",
      "lastUrl",
      "listings",
      "CountAll",
      "PageSize",
      "PageNo",
      "getCountryByCode",
    ]),
  },
  watch: {
    $route(newVal, oldVal){
      if(newVal.fullPath === oldVal.fullPath) return false;
      if (newVal.name === "SearchResults" && this.lastUrl !== newVal.fullPath) {
        this.$store.dispatch('setPageAttr',{
          LastPosition: 0,
          LastUrl: newVal.fullPath
        });
        this.$nextTick(() => {
          this.getListings({ loadMore: false });
        })
        this.loading = false
      }
    },
    getUserFilters(userFilters, oldUserFilter) {
      if (this.$route.name !== "SearchResults") return false;
      /*const isDiff = Object.keys(userFilters).some(key => {
        console.log(typeof (userFilters[key]),key,userFilters, oldUserFilter)
        if((typeof (userFilters[key]) === 'object') && (userFilters[key]?.length !== oldUserFilter[key]?.length)){
          return true
        } else if(userFilters[key] != oldUserFilter[key]){
          return true
        }
        return false
      })*/
      const isDiff = JSON.stringify(userFilters) !== JSON.stringify(oldUserFilter);
      if(!isDiff) return false
      //this.PageNo = 1;
      const queries = _cloneDeep(userFilters);
      if(queries.Make) queries.Make = this.getUserFilterValToLabel("Make", queries.Make);
      if(queries.Model) queries.Model = this.getUserFilterValToLabel("Model", queries.Model);

      this.$router.push({
        name: this.$route.name,
        params: { ...this.$route.params },
        query: { ...queries },
      });
    },
  },
  methods: {
    getNext() {
      //this.PageNo += 1;
      this.getListings({ loadMore: true });
    },
    lastPosition(){
      return parseInt(localStorage.getItem(searchLastPosition)) || 0;
    },
    savePosition(){
      localStorage.setItem(searchLastPosition, window.scrollY.toString())
      this.$store.dispatch('setPageAttr',{
        LastUrl: this.$route.fullPath
      });
    },
    async getListings(payload = { loadMore: false, searchKeys: false }) {
      //if (this.loading) return false
      if (!this.$route.params.country) {
        this.$bvModal.show("selectDeliveryCountryCurrency");
        this.loading = true
        //this.$router.push({name: "Home" })
        return;
      }
      try {
        if (payload.loadMore) {
          this.loadingMore = true;
        } else {
          this.loading = true;
        }
        const body = {
          country: this.$route.params.country,
          PageSize: this.PageSize,
          PageNo: payload.loadMore ? this.PageNo + 1 : 1,
          Prefixes: payload.searchKeys ? payload.searchKeys : "",
          loadMore: payload.loadMore
        };

        const filters = _cloneDeep(this.getUserFilters);
        const isFilter = Object.keys(filters).some(
          (key) => filters[key] || filters[key]?.length > 0
        );

        if (isFilter) {
          const Tags = [];
          Object.keys(filters).forEach((key) => {
            if (key === "Year" && filters[key].length > 1) {
              Tags.push({
                Values: [filters[key][0].toString()],
                TagId: 1,
                Operator: ">"
              });
              Tags.push({
                Values: [filters[key][1].toString()],
                TagId: 1,
                Operator: "<"
              });
            } else if (key === "Sort" && filters[key]) {
              body.Sort = filters.Sort;
            } else if (filters[key] && filters[key].length > 0) {
              const tag = _find(
                this.filters,
                (filter) =>
                  filter.TagId && filter.TagId == FilterTagMapping[key]
              );
              if (tag) {
                Tags.push({
                  Values: filters[key].map((o) => o.toString()),
                  TagId: tag.TagId
                });
              }
            }
          });
          body.Tags = Tags;
        }
        const response = await this.$store.dispatch("getListings", body);

        this.newFilters = response.Filters;
        //this.CountAll = response.CardsCount;
      } catch (error) {
        console.log(error);
        //if (payload.PageNo === 1) this.listings = [];
        this.error = error;
      } finally {
        this.loading = false;
        this.loadingMore = false;
      }
    },
  },
  mounted() {
    if(!this.listings){
      this.getListings()
    }else if(this.lastUrl === this.$route.fullPath && (this.listings && this.listings.length > 0)){
      window.scrollTo(0, 0)
      setTimeout(() => {
        window.scrollTo(0, this.lastPosition())
      },1000)
    }
  },
  beforeRouteLeave(to, from, next) {
    this.savePosition();
    window.removeEventListener("beforeunload", function() {
      localStorage.setItem(searchLastPosition, window.scrollY.toString())
    })

    next();
  },
  created() {
    window.addEventListener("beforeunload", function() {
      localStorage.setItem(searchLastPosition, window.scrollY.toString())
    });
  },
  beforeRouteEnter(to, from, next){
    next((vm) => {
      if (vm.lastUrl === to.fullPath && (vm.listings && vm.listings.length > 0)){
        setTimeout(() => {
          window.scrollTo(0, vm.lastPosition())
        },1000)

      }else{
        vm.$store.dispatch('setPageAttr',{
          LastPosition: 0,
          LastUrl: null
        });
        const routedCountry = to.params.country;
        const routedCurrency = to.params.currency;
        if (routedCountry) {
          const selectedCountry = vm.activeBuyerCountries.find(
              (item) => item.Code === routedCountry
          );
          if (selectedCountry) vm.$store.dispatch("destinationCountry", selectedCountry);
          if (routedCurrency) vm.$store.dispatch("setCurrency", routedCurrency)
        }

        if (to.query && Object.keys(to.query).length > 0) {
          const queries = _cloneDeep(to.query);
          if (queries.Make) {
            queries.Make = vm.$store.getters.getUserFilterLabelToVal("Make", queries.Make);
          }
          if (queries.Model) {
            queries.Model = vm.$store.getters.getUserFilterLabelToVal(
                "Model",
                queries.Model
            );
          }
          vm.$store.dispatch("setUserAllFilter", _merge(JSON.parse(JSON.stringify(blankUserFilter)), queries));
        } else {
          vm.$store.dispatch("clearUserFilters");
        }

      }

    });
  },
  beforeCreate() {
    const destinationCountry = this.$store.state.search.destinationCountry;
    if (destinationCountry && !this.$route.params.country) {
      this.$router.push({
        name: "SearchResults",
        params: { country: destinationCountry.Code },
        query: { ...this.$route.query },
      });
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/custom.scss";
.SearchBarHeader{
  position: sticky;
  top: 0;
  z-index: 1;
}
input:focus {
  outline: none !important;
  box-shadow: none;
}
select:focus {
  outline: none !important;
  box-shadow: none;
}
.searchSection {
  max-width: 1660px !important;
}
.top {
  position: sticky;
  bottom: 50px;
  margin-top: 50px;
  place-self: end;

  /* visual styling */
  text-decoration: none;
  padding: 10px;
  margin-left: 25px;
  border-radius: 50px;
  color: white;
  background-color: $primary;
}
.shadow-sm {
  min-height: 54px;
  display: flex;
  align-items: center;
}
.search-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 55px;
  button {
    min-width: 338px;
    height: 64px;
    border-radius: 45px;
    border: 1px solid #006ac3;
    background: none;
    color: #006ac3 !important;
    font-size: 20px;
    transition: 0.3s;
    &:hover {
      background: #006ac3 !important;
      color: white !important;
    }
  }
}
.current-count {
  margin-bottom: 16px;
}
@media (max-width: 1680px) {
  .searchSection {
    max-width: 1300px !important;
  }
}
@media (max-width: 1320px) {
  .searchSection {
    max-width: 945px !important;
  }
}
.wrapper {
  display: flex;
  column-gap: 16px;
  justify-content: space-between;
  flex-wrap: wrap;
  .item {
    width: calc((100% / 3) - 11px);
    @media (max-width: 767px) {
      width: calc((100% / 2) - 8px);
    }
    @media (max-width: 575px) {
      width: 100%;
    }
  }
}
</style>
